export const en = {
  menu: {
    contacts: 'CONTACTS',
    vehicles: 'VEHICLES',
    communication: 'COMMUNICATION',
    reports: 'REPORTS',
    help: 'HELP',
    settings: 'Settings',
    logout: 'Logout',
  },
  footer: {
    downloads: 'DOWNLOADS',
    support: 'SUPPORT',
  },
  common: {
    list: {
      count: {
        entry: 'Entry',
        entries: 'Entries',
      },
    },
  },
  login: {
    headline: 'CRM by',
    information: 'Enter your username and password to log in.',
    error: {
      empty: {
        username: 'Please fill out this field.',
        password: 'Please fill out this field.',
      },
      expired: 'Login session is out-of-date. Please reload page.',
      sso: {
        malformed: 'The system send invalid information. Please try again.',
        network: 'Network problems detected. Please try again later.',
        ssoproblems:
          'There was an error while authenticating. Error code suz-sso-01',
        expired: 'Login session is out-of-date. Please reload page.',
        someoneelseloggedin:
          'Another person is logged in on this device. You will now be logged out and redirected to the home page.',
        ssoTicketInvalid:
          'The ticket can not be validated by the DCS System.',
      },
    },
    username: 'USERNAME',
    password: 'PASSWORD',
    signIn: 'LOG IN',
    forgotPassword: 'FORGOT PASSWORD?',
    'The provided credentials are invalid, check for spelling mistakes in your password or username, email address, or phone number.':
    'The access data is not valid. Please check your user name / e-mail or password for spelling mistakes. Alternatively, you can reset your password via the “Forgot your password?” link to reset it'
  },
  'set-password': {
    'missing-field': 'Please input required fields',
    'not-identical': 'The password in both fields needs to be identical!',
    information: 'Please set a new password',
    password: 'Password',
    'repeated-password': 'Password again',
    send: 'Set new password',
    'policy-rules': 'Please enter a password with more then 8 chars.',
    'The password can not be used because password length must be at least 8 characters but only got 1.':
      'The password can not be used because password length must be at least 8 characters but only got 1.',
    'The password can not be used because password length must be at least 8 characters but only got 2.':
      'The password can not be used because password length must be at least 8 characters but only got 2.',
    'The password can not be used because password length must be at least 8 characters but only got 3.':
      'The password can not be used because password length must be at least 8 characters but only got 3.',
    'The password can not be used because password length must be at least 8 characters but only got 4.':
      'The password can not be used because password length must be at least 8 characters but only got 4.',
    'The password can not be used because password length must be at least 8 characters but only got 5.':
      'The password can not be used because password length must be at least 8 characters but only got 5.',
    'The password can not be used because password length must be at least 8 characters but only got 6.':
      'The password can not be used because password length must be at least 8 characters but only got 6.',
    'The password can not be used because password length must be at least 8 characters but only got 7.':
      'The password can not be used because password length must be at least 8 characters but only got 7.',
    'The password can not be used because the password has been found in data breaches and must no longer be used.':
      'The password can not be used because the password has been found in data breaches and must no longer be used.',
  },
  'forget-password': {
    information:
      'Please enter your username so that we can send you an email with information on how to reset your password.',
    username: 'USERNAME',
    empty: 'Please fill out this field.',
    'invalid-email': 'Email is not valid.',
    reset: 'RESET PASSWORD',
    'back-to-login': 'BACK TO LOGIN',
    success:
      'You have been sent instructions on how to reset your password. Please follow the instructions in the email.',
  },
  '2fa': {
    information: 'Please enter the code from your Authenticator app',
    code: 'CODE',
    empty: 'Please fill out this field',
    'invalid-code': 'code is invalid.',
    confirm: 'CONFIRM',
    cancel: 'CANCEL',
  },
  'user-config': {
    'users-groups': 'Users and Groups',
    'registered-users': 'Registered Users',
    'logged-users': 'Logged in Users',
    groups: 'Groups',
    policies: 'Policies',
    dealers: 'Dealers',
    'imported-dealers': 'Imported dealers',
    'contact-pools': 'Contact Pools',
    'pools-list': 'List of Pools',
  },
  'user-list': {
    title: 'Registered Users',
    'add-user': 'Register New User',
    edit: 'Edit user',
    delete: 'Delete user',
    column: {
      username: 'Username',
      name: 'Full Name',
      email: 'Email',
      groups: 'Associated Groups',
      actions: 'Actions',
      edit: 'edit',
      delete: 'delete',
      isAdmin: {
        column: 'Admin',
        yes: 'User is admin',
        no: 'User is not admin',
      },
      isAreaAdmin: {
        column: 'ASM',
        yes: 'User is area admin',
        no: 'User is not area admin',
      },
      isDsmAdmin: 'DSM',
    },
    filter: {
      search: 'username, name, email...',
    },
  },
  'user-edit': {
    title: 'Edit user',
    'basic-data': 'Basic Data',
    registration: 'Registrierung',
    username: 'Username',
    firstname: 'Firstname',
    lastname: 'Lastname',
    email: 'E-mail',
    isAdmin: 'Admin',
    isAreaAdmin: 'ASM',
    dealer: 'dealer',
    cancel: 'Cancel',
    submit: 'Submit',
  },
  contacts: {
    contacts: 'Contacts',
    create: 'Create',
    hide: 'Hide contact',
    'people-companies-item': 'People / Companies',
    'import-item': 'Import',
    import: {
      'excel-import-success': 'Successfully imported contacts',
      'excel-import-wrong-format':
        'Wrong file format. Supported formats: xlsx, xlsm, xltm, zip.',
      'download-template': 'Download template',
      'manual-data-import': 'Manual data import',
      'excel-import-result': 'Manual data import result',
      row: 'Row',
      salutation: 'Salutation',
      dealerNumber: 'Dealer number',
      consentDate: 'Consent date',
      result: 'Result',
      error: {
        'consent-date': 'Invalid consent date',
        dealer: 'Invalid dealer number',
        'empty-required-field': 'Empty required field',
        'contact-saving': 'Contact could not be saved',
        dpd: 'Failed to add consents entry',
      },
      failed: 'Failed to import: ',
      imported: 'Row imported',
      'with-errors': ' with errors: ',
      'without-errors': ' without errors',
      'open-saved': 'Open saved contact',
    },
    'people-companies': {
      title: 'People and Companies',
      people: 'People',
      companies: 'Companies',
      list: 'List',
      name: 'Name',
      additional: 'Contact: ',
      address: 'Address',
      email: 'E-mail',
      phone: 'Phone',
      vehicle: 'Vehicle',
      filter: {
        search: 'Name, Surname, Address...',
        origin: {
          title: 'Origin',
          salesImport: 'Sales Import',
          guaranteeImport: 'Guarantee import',
          serviceMessage: 'Service message',
          newsletterReg: 'Newsletter registration',
          testDrive: 'Test drive request',
          contactRequest: 'Contact request',
        },
        consent: {
          title: 'Consents',
          postYes: 'Post yes',
          postNo: 'Post no',
          postUnknown: 'Post unknown',
          emailYes: 'E-mail yes',
          emailNo: 'E-mail no',
          emailUnknown: 'E-mail unknown',
          phoneYes: 'Phone yes',
          phoneNo: 'Phone no',
          phoneUnknown: 'Phone unknown',
          maFoYes: 'MaFo yes',
          maFoNo: 'MaFo no',
          maFoUnknown: 'MaFo unknown',
          messengerYes: 'Messenger yes',
          messengerNo: 'Messenger no',
          messengerUnknown: 'Messenger unknown',
        },
        status: {
          title: 'Status',
          client: '"S-K" (Client)',
          prospect: '"S-I" (Prospect)',
          'lost-customer': '"S-LC" (Lost customers)',
        },
        deliverability: {
          title: 'Deliverability',
          yes: 'Yes',
          no: 'No',
          unknown: 'Unknown',
        },
        'vehicle-type': 'Vehicle type',
        address: {
          title: 'Address',
          'with-address': 'Show contact(s) with (full) address',
          'without-address': 'Show contact(s) without (full) address',
        },
        'hidden-contacts': {
          title: 'Hidden contacts',
          'show-contacts': 'Show hidden contacts',
        },
      },
      'unhide-button': {
        title: 'unhide contacts',
        'no-contacts-selected': 'not contact is selected',
        'contacts-unhiding-success': 'All selected contacts are unhidden now.',
        'contacts-unhiding-partial-success':
          'Failed to unhide all contacts. Number of contacts not unhidden:',
        'contacts-unhiding-failure': 'Contacts unhiding failed.',
      },
    },
    'dpd-report': {
      title: 'Consents Report',
      'title-contact': 'People Consents Report',
      'title-company': 'Companies Consents Report',
      customer: 'Customer',
      post: 'Post',
      email: 'E-mail',
      telephone: 'Phone',
      mobilephone: 'Mobilephone',
      sms: 'SMS',
      fax: 'Fax',
      messenger: 'Messenger',
      maFo: 'MaRe',
      'daten-weitergabe': 'Data-Transfer',
      unknown: 'Unknown',
      true: 'True',
      false: 'False',
      available: 'Available',
      required: 'Required',
      value: 'Release',
      declaration: 'Declaration',
      'document-status': 'Document Status',
      dealernumber: 'Dealer Number',
      asm: 'ASM/AMA',
      dsm: 'DSM/AMA',
      'export-csv': 'CSV Export',
    },
    'hidden-contact': {
      'hidden-for-a-reason':
        'The record you were looking for was deleted for the following reason:',
      questions: 'If you have any questions, please contact the CRM hotline.',
    },
  },
  'contact-details': {
    'not-specified': 'Not specified',
    'created-at': 'Created at {0} by {1}',
    'modified-at': 'Modified at {0} by {1} ',
    back: 'BACK',
    'about-contact': 'About contact',
    isDealer: 'Contact is dealer',
    number: 'Contact number',
    address: 'Address',
    phone: 'Phone',
    email: 'E-mail',
    birthday: 'Birthday',
    company: 'company',
    customer: 'customer',
    'founding-year': 'Founding year',
    'days-to-birthday': "The contact's birthday is in {0} days",
    'birthday-today': "The contact's birthday is today",
    'household-data': {
      title: 'Household data',
      'persons-at-home': 'Persons in household',
      job: 'Job',
      'marital-status': 'Marital status',
      leisureaction: 'Leisure activities',
      sports: 'Sports',
    },
    vehicles: {
      title: 'Vehicles',
      vin: 'VIN',
      'first-registration': 'First registration',
      ownership: 'Ownership',
      'more-information': 'MORE INFORMATION',
    },
    'company-contact': {
      title: 'Contact person',
      'more-information': 'MORE INFORMATION',
    },
    actions: 'Actions',
    buttons: {
      tooltip: {
        'edit-contact': 'Edit contact',
        dpd: 'Declaration of consent under data protection law',
        'add-action': 'Add action',
      },
    },
    dpd: {
      post: 'Post',
      email: 'E-mail',
      sms: 'SMS/MMS',
      telephone: 'Phone',
      mobilephone: 'Mobile',
      fax: 'Fax',
      mare: 'Market research',
      'no-releases': 'No releases',
      private: 'Private',
      business: 'Business',
      title: 'Data release',
      'new-entry': 'Create new entry',
      'show-history': 'Show history',
    },
    history: {
      title: 'Contact history',
      editor: 'Modified by {0}',
      value: '{0} change from {1} to {2}',
      'no-data': 'No change history.',
      attribute: {
        salutation: 'Salutation',
        title: 'Title',
        firstname: 'Firstname',
        name: 'Surname',
        deliverable: 'Dliverable',
        street: 'Street',
        zip: 'ZIP',
        city: 'City',
        countrycode: 'Country code',
        email: 'E-mail',
        slc_email: 'SLC E-mail',
        slc_sms_business: 'SLC Sms busines',
        slc_fax_business: 'SLC Fax business',
        slc_mobile: 'SLC Mobile',
        modified: 'Modified',
        slc_fax: 'SLC FAX',
        securitylawcode: 'Security law code',
        channel: 'Channel',
        import_module: 'Import module',
        slc_last_update: 'SLC Last update',
        slc_post_business: 'SLC Post business',
        dms_xml: 'DMS xml',
        mcid: 'MC ID',
        id: 'Id',
        mvg_owner: 'Mvg Owner',
        bookable_rules_version: 'Bookable rules version',
        customerstate: 'Customer state',
        created: 'Created',
        active: 'Active',
        dealercode: 'Dealercode',
        classification_id: 'Classification id',
        origin: 'Origin',
        bookable: 'Bookable',
        duplicate_group_id: 'Duplicate group id',
        companybranch_id: 'Company branch id',
        new_dms_created: 'New DMS created',
        districtareacode: 'District area code',
        slc_editor: 'SLC Editor',
        initial_import_module: 'Initial import module',
        initial_origin: 'Initial origin',
        initial_channel: 'Initial channel',
        new_dms_modified: 'New DMS modified',
        phone: 'Phone',
        index_update_uid: 'Index update uid',
        slc_sms: 'SLC Sms',
        slc_phone: 'SLC Phone',
        slc_mobile_business: 'SLC Mobile business',
        securitylawcodelocked: 'Security law code locked',
        slc_phone_business: 'SLC Phone business',
        slc_email_business: 'SLC Email business',
        slc_mare_business: 'SLC Mare business',
        slc_mare: 'SLC Mare',
        birthyear: 'Birthyear',
        familystatus_id: 'Family status id',
        title_id: 'Title id',
        function_id: 'Function id',
        person_id: 'Person id',
        childsathome1318: 'Childsathome1318 ',
        childsathome612: 'Childsathome612 ',
        childsathome: 'Childsathome',
        childsathome05: 'Childsathome05',
        personsathome: 'Personsathome',
        company_id: 'Company id',
        disabling_reason_description: 'Disabling reason description',
        disabling_reason_type: 'Disabling reason type',
        bookable_reason: 'Bookable reason',
        disabling_editor: 'Disabling editor',
        disabling_date: 'Disabling date',
        fax: 'Fax',
        web: 'Website',
        birthdate: 'Birthdate',
      },
    },
    'hidden-vehicles': {
      title: 'Hidden vehicles',
      'show-vehicles': 'Show hidden vehicles',
    },
    'unhide-button': {
      title: 'unhide vehicles',
      'no-contacts-selected': 'not vehicle is selected',
      'vehicles-unhiding-success': 'All selected vehicles are unhidden now.',
      'vehicles-unhiding-partial-success':
        'Failed to unhide all vehicles. Number of vehicles not unhidden:',
      'vehicles-unhiding-failure': 'Vehicles unhiding failed.',
    },
  },
  'contact-form': {
    'edit-contact': 'Edit contact',
    'personal-data': 'Personal data',
    'kontakt-data': 'Kontakt data',
    'household-data': 'Household data',
    registration: 'Registration',
    gender: 'GENDER',
    title: 'TITLE',
    'title-placeholder': 'Dr., Prof., Dipl.',
    firstname: 'FIRSTNAME',
    surname: 'SURMANE',
    name: 'NAME',
    birthday: 'BIRTHDAY',
    street: 'STREET',
    address: 'ADDRESS',
    postcode: 'ZIP',
    city: 'CITY',
    country: 'COUNTRY',
    email: 'E-mail',
    phone: 'PHONE',
    fax: 'FAX',
    personsInHousehold: 'PERSONS IN HOUSEHOLD',
    job: 'JOB',
    maritalStatus: 'MARITAL STATUS',
    sports: 'SPORTS',
    activities: 'FREE TIME ACTIVITIES',
    cancel: 'CANCEL',
    submit: 'SAVE CONTACT',
    dropdownEmpty: 'please choose',
    vin: 'VIN',
    company: 'COMPANY',
    errors:{
      'error-vin': 'VIN could not be found! Please check our input again',
      'error-wrong-email': 'Please enter a correct email address!',
      'error-required': 'Please complete all the required fields',
      'name-required': 'Please enter the first and last name',
      'dealer-required': 'Please select a dealer',
      'phone-required': 'Please enter a phone number',
      'email-or-adress-required': 'Please enter either an e-mail address or a full address',
      'invalid-street': 'Please enter a complete street!',
    },
    success: 'Saved successfully',
    error: 'An error has occurred. Please contact support:  ',

  },
  gender: {
    Herr: 'Mr',
    Frau: 'Mrs',
    Divers: 'Miscellaneous',
  },
  'device-type': {
    private: 'private',
    mobile: 'mobile',
    work: 'business',
    business: 'business',
  },
  'marital-status': {
    NOCOMMENT: 'no comment',
    MARRIED: 'married',
    SINGLE: 'single / living alone',
    LIVINGTOGETHER: 'living together',
    DIVORCED: 'divorced / separated',
    WIDOWED: 'widowed',
  },

  'sports-list': {
    NOCOMMENT: 'no comment',
    SOCCER: 'Soccer',
    'SAILING-WINDSURFING': 'Sailing/Windsurfing',
    'SKIING-SNOWBOARDING': 'Skiing/Snowboarding',
    TENNIS: 'Tennis',
    SWIMMING: 'Swimming',
    SQUASH: 'Squash',
    GYM: 'Gym',
    'INLINE-SKATING': 'Inline skating',
    'JOGGING-WALKING': 'Jogging/Walking',
    'CYCLING-MOUNTAINBIKING': 'Cycling/Mountain biking',
    GOLF: 'Golf',
    MOTORSPORTS: 'Motorsports',
    OTHER: 'Other',
  },
  'activities-list': {
    NOCOMMENT: 'no comment',
    DIY: 'DIY',
    'VISITING-CULTURAL-EVENTS': 'Visiting cultural events',
    GARDENING: 'Gardening',
    'VISITING-THE-CINEMA': 'Visiting the cinema',
    TRAVEL: 'Travel',
    TELEVISION: 'Television',
    READING: 'Reading',
    'ARTISTIC-WORK': 'Artistic work',
    MUSIC: 'Music',
    OTHER: 'Other',
  },
  'contact-create': 'Add contact',
  'vehicle-create': 'Add vehicle',
  'vehicle-edit': {
    title: 'Edit vehicle',
    'basic-data': {
      title: 'Basic data',
      vin: 'VIN',
      make: 'MAKE',
      model: 'MODEL',
      type: 'TYPE',
      'first-registration': 'FIRST REGISTRATION',
      equipment: 'EQUIPMENT',
      'registration-number': 'REGISTRATION NUMBER',
    },
    'technical-data': {
      title: 'Technical data',
      doors: 'NUMBER OF DOORS',
      color: 'CAR COLOR',
      'engine-volume': 'ENGINE VOLUME',
      fuel: 'FUEL',
    },
    cancel: 'CANCEL',
    submit: 'SAVE VEHICLE',
  },
  'vehicle-details': {
    'created-at': 'Created at {0}',
    back: 'Back',
    'about-section': {
      title: 'About this vehicle',
      vin: 'VIN',
      make: 'Make',
      model: 'Model',
      modelcode: 'Sales Modell Code',
      type: 'Type',
      equipment: 'Equipment',
      'first-registration': 'First registration',
      sign: 'Sign',
      visibility: 'Visibility',
    },
    'mileage-section': {
      title: 'Mileage',
      state: 'State',
      date: 'Date',
      origin: 'Origin',
    },
    actions: 'Actions',
    origin: 'Origin:',
    'registrations-section': {
      title: 'Vehicle registrations',
      number: 'Customer number',
      'more-information': 'MORE INFORMATION',
    },
    'more-data-section': {
      title: 'More data',
      doors: 'Number of doors',
      'body-color': 'Color',
      capacity: 'Capacity',
      fuel: 'Fuel Type/Engine',
    },
    buttons: {
      tooltip: {
        'edit-vehicle': 'Edit vehicle',
        'add-action': 'Add action',
      },
    },
    history: {
      title: 'Vehicle history',
      editor: 'Modified by {0}',
      value: '{0} change from {1} to {2}',
      'no-data': 'No change history.',
      attribute: {
        vin: 'VIN',
        brand: 'Marke',
        'model-name': 'Model name',
        'model-type': 'Model type',
        'model-code': 'Model code',
        'first-registration': 'First registration date',
        'registration-date': 'Registration date',
        'license-number': 'License number',
        // mileage: 'Mileage',
        // 'date-mileage-update': 'Date mileage update',
        origin: 'Origin',
        owner: 'Owner',
        'vehicle-type': 'Vehicle type',
        'vehicle-status': 'Vehicle status',
      },
    },
  },
  vehicles: {
    title: 'Vehicles',
    brand: 'Brand',
    model: 'Model name',
    modelcode: 'Sales Modell Code',
    vin: 'VIN',
    first_registration_date: 'Date of first registration',
    owner: 'Owner',
    hide: 'Hide vehicle',
    deactivate: 'Deactivate vehicle',
    filter: {
      search: 'VIN, Brand, Model',
      'register-date': 'Register date',
      brand: 'Brand',
      model: 'Model',
      type: 'Type',
      'deactivated-vehicles': {
        title: 'Deactivated vehicles',
        name: 'Hide deactivated vehicles',
      },
      registration_date: {
        title: 'Date of first registration',
      },
      models: 'Models',
      model_other_option: 'Other'
    },
    export_csv: {
      button: 'CSV-Export',
      toast: {
        unexpected_error: 'There was an unexpected error while exporting the data. Please try again later. If the error occurs repeatedly, please contact the dealer hotline. We apologize for the error.',
      },
      popup: {
        title: 'Export limitation',
        description: 'Contact export is limited to 5,000 contacts. The current selection exceeds the maximum number of records. Do you want to go ahead and only export the first 5,000 contacts?',
        no: 'Cancel export',
        yes: 'Perform export'
      }
    }
  },
  communication: {
    title: 'Communication',
    'inspections-invitations': {
      title: 'Send invitations',
      sideTitle: 'Send inspection invitation',
      'send-invitations': 'Send invitations',
      'no-contacts-selected': 'not contact is  selected',
      'confirm-count':
        'If you confirm, {0} contacts will be invited for inspection via email.',
      'confirm-count-cars':
        'If you confirm, {0} contacts will be invited for inspection via letter.',
      'email-preview': 'The email to the contacts looks like this:',
      'confirm-continue': 'Do you want to continue?',
      'send-email': 'Yes, send an E-mail',
      'send-emails': 'Yes, send {0} E-mails',
      'send-letter': 'Yes, order a letter sendout',
      'send-letters': 'Yes, order {0} letters sendout',
      'sent-invitations': 'Sent invitations',
      'send-invitation': 'Send invitation',
      'resend-invitation': 'Resend invitation',
      'send-invitation-title': 'Send invitations for inspection',
      'resend-invitation-title': 'Send invitations for inspection',
      'invitation-confirmation-description':
        'Contacts will be notified by email in the next few minutes!',
      'invitation-confirmation-description-cars':
        'Contacts will be notified by letter after next CCP export.',
      'invitation-confirmation-send-date': 'The next shipment will be on {0}. ',
      'invitation-confirmation-close': 'OK',
      motorcycles: 'Motorcycle',
      scooters: 'Scooter',
      placeholder: ' Placeholder value',
      columns: {
        contact: 'Contact',
        email: 'E-mail',
        model: 'Model',
        vin: 'VIN',
        'license-plate': 'license-plate',
        'first-registration': 'First registration',
        'last-inspection': 'Last inspection',
        'done-inspection': 'Response date',
        'expected-inspection': 'Expected inspection',
        'inspection-number': 'Inspection interval',
        'invitation-sent': 'Invitation sent',
        'invitation-sent-pending': 'Wating for sendout',
        'invitation-sendout-failed': 'Wating for sendout, last sendout failed',
        'estimated-milage' :'Estimated milage',
        'inspection-date': 'Inspection date',
        'sendout-errors': {
          result: 'Validation failed for fields: {0}',
          'customer-number': 'customer-number',
          salutation: 'customer-salutation',
          name: 'customer-name',
          street: 'customer-street',
          zip: 'customer-zip',
          city: 'customer-city',
          'country-code': 'customer-country-code',
          'dealer-name': 'dealer-name',
          'dealer-code': 'dealer-code',
          'dealer-zip': 'dealer-zip',
          'dealer-city': 'dealer-city',
          'dealer-phone': 'dealer-phone',
          'dealer-url': 'dealer-url',
          vin: 'vin',
          'first-reg-date': 'first-registration-date',
          brand: 'brand',
          model: 'model',
          'serial-test-number': 'serial-test-number',
          'license-number': 'license-number',
        },
      },
      filters: {
        'search-placeholder': 'VIN, model, name',
        'inspection-date': {
          title: 'Inspection date',
          'not-marked': 'Inspections due in more than 2 weeks (unmarked)',
          'marked-yellow':
            'Inspections due in the next 2 weeks (marked in yellow)',
          'marked-red':
            'Inspections that are up to 2 weeks overdue (marked in red)',
          'marked-red-sent': 'Inspections that are overdue (marked in red)',
        },
        'insepection-number' :{
            title :'Inspection number'
        },
        model: 'Modell',
        mark: 'Kennzeichen',
        'first-registration': 'Erstzulassung',
        'last-inspection': 'Letzte Inspektion',
        'expected-inspection': 'Voraussichtl. Inspektion',
      },
      edit: {
        title: 'Edit inspection date',
        date: 'Inspection date',
        cancel: 'Cancel',
        submit: 'Save and close',
        reset: 'reset',
        confirmation: {
          yes: 'Yes',
          no: 'No',
          title:
            'By changing the expected inspection date, the inspection will no longer appear on the dispatch list. Do you want to accept the date change?',
        },
      },
      delete: {
        title: 'Remove inspection invitation',
        description:
          'Please select a reason for removing the inspection invitation.',
        hint: 'Remove inspection invitation',
        cancel: 'Cancel',
        submit: 'Save',
        options: {
          ALREADY_COMPLETED_SUZUKI:
            'Vehicle was already at another Suzuki dealer for inspection',
          ALREADY_COMPLETED_NON_SUZUKI:
            'The vehicle was not at a Suzuki dealer / open workshop for inspection',
          VEHICLE_SOLD_TO_UNKNOWN:
            'Customer no longer owns vehicle / vehicle sold to unknown person',
          VEHICLE_SCRAPPED: 'Vehicle was scrapped',
          VEHICLE_EXPORTED: 'Vehicle was exported',
          VEHICLE_STOLEN: 'Vehicle was stolen',
          DEACTIVATE_INVITATIONS:
            'Vehicle no longer considered for inspection invitations',
        },
      },
    },
    'completed-inspections' : {
      'columns' : {
        'inspection-date': 'Inspection date',
        'milage': 'Milage',
        'inspectionReason': 'Reason for inspection',
      }
    },
    'extended-warranty-reminder': {
      title: 'Send reminder',
      'to-send': 'Reminders to send',
      sent: 'Sent reminders',
      sideTitle: 'Send reminder of Suzuki Pro follow-up warranty',
      'send-reminder': 'Send reminder of Suzuki Pro follow-up warranty',
      'resend-reminder': 'Resend reminder of Suzuki Pro follow-up warranty',
      'send-reminder-title': 'Send reminder of Suzuki Pro follow-up warranty',
      'send-reminder-confirmation':
        'Reminder of Suzuki Pro follow-up warranty successfully sent',
      'confirm-count':
        'If you confirm, {0} Kontakte per E-Mail an die Anschlussgarantie erinnert.',
      columns: {
        contact: 'Contact',
        email: 'E-mail',
        model: 'Model',
        vin: 'VIN',
        'license-plate': 'license-plate',
        'first-registration': 'First registration',
        'last-inspection': 'Last inspection',
        'expected-inspection': 'Expected inspection',
        'inspection-number': 'Inspection interval',
        'invitation-sent': 'Invitation sent',
        vehicle: 'Vehicle',
        'last-sendout': 'Reminder sent',
        'warranty-expiry-date': 'Warranty expiry ',
      },

      filters: {
        'search-placeholder': 'VIN, Name, E-Mail',
        'date-of-registration': 'Date of registration',
        'show-contacts': 'Filter contact list',
        'show-selected': 'show only selected contacts ',
        'show-unselected': 'Show only deselected contracts',
      },
    },
    'ccp-export': {
      title: 'CCP - Export',
      'service-questionnaire': 'Service Questionnaire',
      'service-questionnaire-header': 'CCP - Service Questionnaire',
      'service-questionnaire-sample-cover-letter':
        'Download sample cover letter',
      'service-questionnaire-sample-questionnaire':
        'Download sample service questionnaire',
      'service-questionnaire-popup': {
        'resend-button': 'Resend',
        title: 'Resend service questionnaires',
        confirmation: 'Service questionnaires will be resend?',
        success: 'Service questionnaire was successfully resent',
        'success-description':
          'Service questionnaires will be sent by letter after next CCP export.',
      },
      'exported-at': 'Versanddatum',
      'sales-questionnaire': 'Sales Questionnaire',
      'sales-questionnaire-header': 'CCP - Sales Questionnaire',
      'sales-questionnaire-sample-cover-letter': 'Download sample cover letter',
      'sales-questionnaire-sample-questionnaire':
        'Download sample sales questionnaire',
      'sales-questionnaire-sample-mobility-card':
        'Download sample mobility card',
      'sales-questionnaire-sample-cover-letter-resending':
        'Download sample cover letter resending',
      'sales-questionnaire-popup': {
        'resend-button': 'Resend',
        title: 'Resend sales questionnaires',
        confirmation: 'Sales questionnaires will be resend?',
        success: 'Sales questionnaire was successfully resent',
        'success-description':
          'Sales questionnaires will be sent by letter after next CCP export.',
      },
    },
    newsletter: {
      title: 'Newsletter',
      list: {
        sideTitle: 'Newsletter subscriptions',
      },
      export: {
        sideTitle: 'Export history',
      },
      verification: {
        sideTitle: 'Unverified subscribers',
      },

      definition: {
        side_title: 'Overview',
        page_header: 'Newsletter',
        table_header_name: 'Name',
        table_header_code: 'Code',
        table_header_tenant: 'Tenant',
        table_header_activation_status: 'Status',
        tooltip_create: 'Create',
        tooltip_edit: 'Edit',
        tooltip_delete: 'Delete',
        tooltip_deactivate: 'Deactivate',
        form: {
          label_name: 'Name',
          label_code: 'Code',
          label_newsletter: 'Newsletter',
          label_tenant: 'Tenant',
          tenant_auto:'AUTO',
          tenant_moto:'MOTO',
          validation_name: 'Name cannot be empty!',
          validation_code: 'Code cannot be empty!',
          validation_tenant: 'Tenant must be selected!',
        },
        popup: {
          success_create: 'Newsletter created successfully.',
          success_edit: 'Newsletter updated successfully.',
          success_delete: 'Newsletter deleted successfully.',
          success_deactivate: 'Newsletter deactivated successfully.',
          error_not_found: 'Newsletter was not found',
          error_invalid_id: 'Provided Newsletter is not valid',
          error_invalid_newsletter: 'Selected Newsletter is invalid',
          error_newsletter_not_modifiable: 'Selected Newsletter can not be modified, because is already subscribed.',
          error_resource_already_exists: 'Newsletter with provided code already exists.',
        },
        filter:{
          label: 'Newsletter status',
          all: 'Show all newsletters',
          active:'Active',
          deactivated:'Inactive',
        },
        modal: {
          title_create: 'Create Newsletter',
          title_edit: 'Edit Newsletter',
          title_delete: 'Delete Newsletter',
          title_deactivate: 'Deactivate Newsletter',
          button_save: 'SAVE',
          button_delete: 'DELETE',
          button_deactivate: 'DEACTIVATE',
          button_cancel: 'CANCEL',
          text_delete: 'Are You sure You want to delete Newsletter?',
          text_deactivate: 'Are You sure You want to deactivate Newsletter?',
        },
      },
    },
    sweepstake: {
      title: 'Sweepstake',
      subpages: {
        overview: 'Overview',
      },
      overview: {
        title: 'Sweepstakes',
        table_header_name: 'Name',
        table_header_code: 'Code',
        table_header_newsletter: 'Newsletter',
        tooltip_create: 'Create',
        tooltip_edit: 'Edit',
        tooltip_delete: 'Delete',
        form: {
          label_name: 'Name',
          label_code: 'Code',
          label_newsletter: 'Newsletter',
          validation_name: 'Name cannot be empty!',
          validation_code: 'Code cannot be empty!',
          validation_newsletter: 'Newsletter must be selected!',
        },
        popup: {
          success_create: 'Sweepstake created successfully.',
          success_edit: 'Sweepstake updated successfully.',
          success_delete: 'Sweepstake deleted successfully.',
          error_not_found: 'Sweepstake was not found',
          error_code_exists: 'Sweepstake with given Code already exists',
          error_invalid_id: 'Provided Sweepstake is not valid',
          error_invalid_newsletter: 'Selected Newsletter is invalid',
          error_already_in_use: 'Sweepstake is in use and cannot be modified/deleted',
        },
        modal: {
          title_create: 'Create Sweepstake',
          title_edit: 'Edit Sweepstake',
          title_delete: 'Delete Sweepstake',
          button_save: 'SAVE',
          button_delete: 'DELETE',
          text_delete: 'Are You sure You want to delete Sweepstake?',
        },
      },
    },

    surveys: {
      receivers: "Receivers",
      invited: "Invited customers",
      title: 'Surveys',
      'moto-sales': 'Sales-Questionnaire',
      'moto-service': 'Service-Questionnaire',
      'moto-roadshow': 'Roadshow-Questionnaire',
      'moto-product': 'Pruduct-Questionnaire',
      'auto-sales': 'Sales-Questionnaire',
      'auto-service': 'Service-Questionnaire',
      'auto-product': 'Product-Questionnaire',
      'moto-sales-prefix': 'Motorbike Sales-Questionnaire',
      'moto-service-prefix': 'Motorbike Service-Questionnaire',
      'moto-roadshow-prefix': 'Motorbike Roadshow-Questionnaire',
      'moto-product-prefix': 'Motorbike Pruduct-Questionnaire',
      'auto-sales-prefix': 'Automobile Sales-Questionnaire',
      'auto-service-prefix': 'Automobile Service-Questionnaire',
      'auto-product-prefix': 'Automobile Product-Questionnaire',
      'sendoutMethod': {
        'email': 'Email',
        'post': 'Post',
      },
      'sendoutErrors': {
        'result': 'Dispatch not possible, because {0}',
// Major errors
        "registration-nil-registration": 'registration not found',
        "vehicle-nil-vehicle": 'vehicle not found',
        "dealer-nil-dealer": 'dealer not found',
        "service-record-nil-service-record": 'service-record not found',
        "invitation-nil-invitation": 'invitation not found',
        "ccp-export-nil-ccp-export": 'ccp-export not found',
        "ccp-export-ccp-export-exist": 'already waiting for sendout',
        "contact-nil-contact": 'contact not found',
        "dpd-nil-dpd": 'dpd records not found',

// Vehicle
        "vehicle-missing-vehicle": 'vehicle not found',
        "vehicle-brand": 'invalid brand',
        "vehicle-model-type": 'invalid model type',
        "vehicle-first-registration": 'vehicle is too old',
        "vehicle-not-active": 'vehicle is not active',
        "vehicle-deactivated": 'vehicle is not active',
        "vehicle-hidden": 'vehicle is hidden',
        "vehicle-vin": 'invalid VIN',
        "vehicle-model": 'invalid model',

// Contact
        "contact-contact-not-found": 'contact not found',
        "contact-securitylawcode-locked": 'contact is locked by admin',
        "contact-customer-number": 'invalid costumer number',
        "contact-salutation": 'invalid costumer salutation',
        "contact-name": 'invalid costumer name',
        "contact-bookable": 'contact is not bookable',
        "contact-street": 'invalid costumer street',
        "contact-zip": 'invalid costumer zip',
        "contact-city": 'invalid costumer city',
        "contact-countrycode": 'invalid costumer country code',
        "contact-email": 'invalid costumer email',
        "contact-company-is-dealer": 'contact is a dealer',

// Dealer
        "dealer-name": 'invalid dealer name',
        "dealer-dealercode": 'invalid dealer code',
        "dealer-address-line": 'invalid dealer address',
        "dealer-zip": 'invalid dealer zip',
        "dealer-city": 'invalid dealer city',
        "dealer-phone": 'invalid dealer phone',
        "dealer-email": 'invalid dealer email',
        "dealer-homepage": 'invalid dealer homepage',

// DPD
        "dpd-dpd-email": 'invalid email consent',
        "dpd-dpd-post": 'invalid post consent',
        "dpd-dpd-mare": 'invalid rarket research consent',
        "dpd-dpd-admin-lock": 'locked by admin',

// Service_Record
        "service-record-record-is-not-inspection": 'record is not inspection',
        "service-record-reference-id": 'invalid service record reference',
        "service-record-not-first-inspection": 'service record not for first inspection',
        "service-record-nil-visit-date": 'empty service date',
        "service-record-visit-date": 'invalid service date',
        "service-record-first-registration-date": 'invalid first registration date',
        "service-record-last-sendout": 'invalid last sendout',
        "service-record-last-sendout-nill": 'invalid last sendout',
        "service-record-dealer-deleted": 'dealer is deleted',

// Registration
        "registration-missing-vin": 'invalid VIN',
        "registration-contact": 'invalid contact',
        "registration-company": 'invalid company',
        "registration-not-active": 'inactive registration',
        "registration-not-firstregistration": 'not first registration',
        "registration-not-new-registration": 'not new registration',
        "registration-days-after-first-registration": 'registration to old',
        "registration-dealer": 'invalid dealer',
        "registration-license-number": 'invalid license number',
        "registration-customer-type": 'invalid customer type',
        "registration-email": "contact has no email address",
        "registration-company-is-dealer": "contact is a Suzuki dealer",
      },
      columns: {
        vehicle: 'Vehicle',
        'sendoutDate': 'Calculated Dispatch Date',
        'sendoutMethod': 'Dispatch method',
        'sendoutFailed': 'Dispatch failed',
        'progress': 'Status',
      },
      progressState: {
        NOT_STARTED: "Not started",
        IN_PROGRESS: "In progress",
        COMPLETED: "Ausgefüllt",
      },
      filters: {
        'search-placeholder': 'VIN, Name, E-Mail...',
        progressState: "Status"
      },
    },
    'test-drive': {
      title: 'Test drives',
      origin: {
        NOT_SET: 'Not set',
        NATIONAL_WEBSITE: 'National website',
        DEALER_WEBSITE: 'Dealer\'s website',
        SCRM: 'SCRM',
        TEST_DRIVE_WEEK: 'Test drive week',
        DATA_COLLECTION_TOOL: 'Data Collection Tool',
        IMOT_2024_MUNICH: 'IMOT 2024 München',
        MOTORRAD_DORTMUND: 'Motorrad Dortmund',
        ROADSHOW: 'Roadshow',
      },
      status: {
        NEW_OPEN: 'Test drive new/open',
        SCHEDULED: 'Test drive scheduled',
        FINISHED_CARRIED_OUT_BOUGHT: 'Test drive completed (vehicle purchased)',
        FINISHED_CARRIED_OUT_NOT_BOUGHT: 'Test drive completed (no vehicle purchase)',
        FINISHED_CARRIED_OUT_PURCHASE_OPEN: 'Test drive completed (vehicle purchase pending)',
        FINISHED_CARRIED_OUT_PURCHASE_OPEN_HOT_LEAD: 'Test drive completed (vehicle purchase pending (hot lead))',
        FINISHED_NOT_CARRIED_OUT: 'Test drive finished (not carried out)',
        CANCELED: 'Test drive finished (canceled)',
        'no-data': 'no data',
      },
      report: {
        title: 'Overview',
        auto: "Auto",
        moto: "Motorcycle",
        date: 'Date',
        plannedDate: 'Test drive date (planned)',
        model: 'Model',
        dealercode: 'Dealer number',
        dealerName: 'Dealer Name',
        'contact-name': 'Contact name',
        email: 'E-Mail',
        phone: 'Phone',
        origin: 'Testdrive origin',
        status: 'Test drive status',
        search: 'Date, Model, Name...',
        'asm-area': 'ASM',
        dealer: 'Dealer',
        'main-dealer': 'Main dealer',
        'date-from-to': 'Period from/to',
        back: 'Back',
        modals: {
          'created-at': 'Created at {0} by {1}.',
          'modified-at': 'Last time modified at {0} by {1}.',
          'not-specified': 'not specified',
          model: 'Model for a test drive: ',
          'wish-date': 'Wish date: ',
          prospect: {
            name: 'Name: ',
            surname: 'Surname: ',
            'company-name': 'Company name: ',
            phone: 'Phone: ',
            'e-mail': 'E-mail address: ',
          },
          comment: 'Comment: ',
          'prospect-notice': 'Comment of prospect: ',
          'selected-date': 'Scheduled date: ',
          close: 'Close',
          save: 'Save & close',
          'download-agreement': 'Transfer agreement',
          interested_in_vehicle: 'interested in vehicle',
          not_interested_in_vehicle: 'not interested',
          radio: {
            UNKNOWN: 'Unknown',
            TRUE: 'Yes',
            FALSE: 'No'
          },
          yes: 'Yes',
          no: 'No',
          'response-label': 'Test drive response (*): ',
          response_label_not_required: 'Test drive response: ',
          response: {
            performed: 'Test drive performed: ',
            interested: 'Interested in vehicle: ',
            purchase: 'Purchase of vehicle: ',
            'purchase-vin': 'VIN: ',
            'purchase-vin-placeholder': 'VIN of the purchased vehicle',
            'vin-empty': 'Please, provide VIN of the bought vehicle.',
          },
          'schedule-test-drive': {
            'button-tooltip': 'Test drive scheduling',
            'modal-title': 'Test drive scheduling',
            'date-selection-tooltip': 'Select date of the test drive',
            'successfully-scheduled': 'The test drive was successfully updated.',
            'scheduled-date-not-set': 'Please enter a date and time to save the test drive.',
          },
          'cancel-test-drive': {
            'modal-title': 'Cancel test drive',
            'reason-for-canceling': 'Reason for canceling (*): ',
            'successfully-canceled': 'Test drive has been successfully canceled.',
            'fill-required-fields': 'All required fields have to be filled.',
          },
          'finish-test-drive': {
            'button-tooltip': 'Complete test drive request',
            'modal-title': 'Complete test drive request',
            'successfully-finished': 'Test drive has been successfully completed.',
            'fill-required-fields': 'All required fields have to be filled.',
          },
          show_test_drive_details: {
            button_tooltip: 'Show test drive details',
            modal_title: 'Show test drive details',
            reason_for_canceling_not_required: 'Begründung für Stornierung: ',
          },
          'question-test-drive-title': 'Create new test drive request?',
          'question-test-drive-creation': 'Do you want to create new test drive request for this contact?',
          'create-test-drive': {
            'button-tooltip': 'New test drive creation',
            'modal-title': 'Create new test drive request',
            'successfully-created': 'The test drive has been successfully created.',
            'fill-required-fields': 'Please fill out all mandatory fields (*) to create the test drive.',
          },
          'planned-date-in-the-past': 'The date of the test drive has already past. Is that correct?',
        },
        'filter-option': {
          from: 'From',
          to: 'To',
          title: 'Date Type ',
          createAt: 'Test drive date',
          plannedDate: 'Test drive date (planned)',
        },
        origin_filter: {
          name: 'Test drive origin'
        },
        status_filter: {
          name: 'Test drive status'
        }
      },
      import: {
        title: 'Import of completed test drives',
        button: 'Import',
        'excel-import-success': 'Test drives import was successful.',
        'excel-import-failed': 'Import failed, file contains invalid data.',
        'excel-import-wrong-format':
          'Wrong file format. Supported formats: xlsx, xlsm, xltm.',
      },
    },
    'web-request': {
      title: 'Leads',
      dealercode: 'Dealer code',
      models: 'Models',
      'contact-number': "Customer number",
      'contact-email': 'E-Mail',
      'contact-name': "Name",
      'created-at': "Creation Date",
      completed: 'Contacted',
      company_name: 'Company name',
      business_type_header: 'Business area',
      business_type: {
        NOT_SET: '-',
        CARE_SERVICES: 'Care services',
        DELIVERY_SERVICES: 'Delivery services',
        SMALL_BUSINESS: 'Small business',
        DRIVING_SCHOOL: 'Driving school',
        CAB_COMPANY: 'Cab company',
      },
      subpages: {
        offers: 'Offer requests private customers',
        business_offers: 'Offer requests business customers',
        'car-configuration-offers': 'Car configuration offers',
        'brochure-downloads': 'Brochure downloads',
        'consulting-appointments': 'Consulting appointments',
        'service-appointments': 'Service appointments',
        'sweepstake-subscriptions': 'Sweepstake subscriptions',
      },
      'search-placeholder': 'Customer number, E-Mail, Name...',
      'completion-failed': 'An error has occurred. Please try again.',
      'sweepstake-name': 'Sweepstake name',
      'subscription-handled': ' Contacted',
      'subscription-purchase-intent': {
        title: "Change of vehicle",
        UNKNOWN: "Not sure yet",
        LEASING: "Yes, with Leasing",
        FINANCING: "Yes, with financing",
        CASH_PURCHASE: "Yes, as a cash purchase",
        NOT_BUY: "No",
      },
      'subscription-vehicle-class': {
        title: "Vehicle type",
        OTHER: "Other",
        SMALL_CAR: "Small car",
        SUV: "SUV",
        COMBI: "Combi",
        LIMOUSINE: "Limousine",
        VAN: "VAN",
        UNKNOWN_VC: "I do not know yet",
      },
      'subscription-vehicle-engine': {
        title: "Engine type",
        UNKNOWN_VE: "I do not know yet",
        PETROL: "Petrol engine",
        DIESEL: "Diesel engine",
        ELECTRO: "Electric drive",
        HYBRID: "Hybrid drive (Plug-In, full hybrid)",
        OTHER_VE: "Other",
      },
    }
  },
  register: {
    title: 'Create new user',
    'basic-data': 'Basic data',
    active: 'USER IS ACTIVE',
    username: 'USERNAME',
    firstname: 'FIRST NAME',
    lastname: 'LAST NAME',
    email: 'E-mail',
    cancel: 'CANCEL',
    submit: 'SAVE USER',
    password: 'Password',
    isAdmin: 'Is the user an admin?',
    isAreaAdmin: 'Is the user an ASM or DSM?',
    dealer: 'Dealer',
  },
  'dealer-information': {
    title: 'Dealer {{number}}',
    'basic-data': {
      title: 'Basic data',
      createdAt: 'Created at',
      number: 'Dealer number',
      name: 'Dealer name',
      'name-addition': 'Dealer name addition',
    },
    'contact-data': {
      title: 'Contact details',
      phone: 'Phone',
      fax: 'Fax',
      'dealer-email': 'Dealer E-mail',
      'suzuki-email': 'Suzuki E-mail',
      homepage: 'Home page',
      homepage2: 'Home page 2',
    },
    'address-data': {
      title: 'Address',
      street: 'Street',
      postcode: 'Postcode',
      city: 'Location',
    },
    'opening-hours': {
      title: 'Opening hours',
      sale: 'Sale',
      service: 'Service',
      monday: 'Monday:',
      tuesday: 'Tuesday:',
      wednesday: 'Wednesday:',
      thursday: 'Thursday:',
      friday: 'Friday:',
      saturday: 'Saturday:',
      sunday: 'Sunday:',
    },
    amrDsmArea: {
      title: 'sales/ aftersales Area',
      amr: 'sales Area',
      dsm: 'aftersales Area',
    },
    'contract-data': {
      title: 'Contract data',
      'distribution-contract': 'Distribution contract',
      'distribution-contract-end': 'Distribution contract eliminated',
      'service-contract': 'Service contract',
      'service-contract-end': 'Service contract eliminated',
    },
    back: 'BACK',
    'data-reimported': 'Import successful',
    'data-reimported-error': 'There was an error during import ',
    'data-reimported-msg': 'Data is importing',
  },
  'contact-pools': {
    title: 'Kontaktpools',
    'pool-id': 'Pool ID',
    'dealer-name': 'Händlername',
    'dealer-number': 'Händlernummer',
    'number-of-contacts': 'Anzahl an Kontakten',
    actions: 'Aktionen',
  },
  'bar-chart': {
    avg: 'Average',
    legend: {
      keys: {
        plannedInspections: 'Planned Inspections',
        performedInspections: 'Performed Inspections',
      },
    },
  },
  crm: {
    'asm-range': 'ASM-Gebiet',
    'dsm-range': 'DSM-Gebiet',
    'main-dealer': 'Main Dealer',
    dealer: 'Dealer',
    'customer-retention-rate': 'Customer retention rate',
    'customer-retention-rate-desc-1-5':
      'Customer retention rate (CRR) - 1.-5. inspection',
    'customer-retention-rate-desc-1-10':
      'Customer retention rate (CRR) - 1.-10. inspection',
    shop: 'Shop',
    'shop-cart-items': 'Shop cart items',
    'website-metrics': {
      title: 'Website metrics',
      'survey-tool': {
        'kpi': 'CSI Kennzahlen',
        'sent-auto-service-prefix': 'Auto Service-Questionnaire - Survey invitations sent out',
        'finished-auto-service-prefix': 'Auto Service-Questionnaire - Answered questionnaires',
        'percentage-auto-service-prefix': 'Auto Service-Questionnaire - Response rate (in %)',
        'sent-auto-sales-prefix': 'Auto Sales-Questionnaire - Survey invitations sent out',
        'finished-auto-sales-prefix': 'Auto Sales-Questionnaire - Answered questionnaires',
        'percentage-auto-sales-prefix': 'Auto Sales-Questionnaire - Response rate (in %)',
        'sent-auto-product-prefix': 'Auto Produkt-Questionnaire - Survey invitations sent out',
        'finished-auto-product-prefix': 'Auto Produkt-Questionnaire - Answered questionnaires',
        'percentage-auto-product-prefix': 'Auto Produkt-Questionnaire - Response rate (in %)',
        'sent-moto-service-prefix': 'Motorrad Service-Questionnaire - Survey invitations sent out',
        'finished-moto-service-prefix': 'Motorrad Service-Questionnaire - Answered questionnaires',
        'percentage-moto-service-prefix': 'Motorrad Service-Questionnaire - Response rate (in %)',
        'sent-moto-sales-prefix': 'Motorrad Sales-Questionnaire - Survey invitations sent out',
        'finished-moto-sales-prefix': 'Motorrad Sales-Questionnaire - Answered questionnaires',
        'percentage-moto-sales-prefix': 'Motorrad Sales-Questionnaire - Response rate (in %)',
        'sent-moto-product-prefix': 'Motorrad Produkt-Questionnaire - Survey invitations sent out',
        'finished-moto-product-prefix': 'Motorrad Produkt-Questionnaire - Answered questionnaires',
        'percentage-moto-product-prefix': 'Motorrad Produkt-Questionnaire - Response rate (in %)',

        'sent-auto-service': 'Service-Questionnaire - Survey invitations sent out',
        'finished-auto-service': 'Service-Questionnaire - Answered questionnaires',
        'percentage-auto-service': 'Service-Questionnaire - Response rate (in %)',
        'sent-auto-sales': 'Sales-Questionnaire - Survey invitations sent out',
        'finished-auto-sales': 'Sales-Questionnaire - Answered questionnaires',
        'percentage-auto-sales': 'Sales-Questionnaire - Response rate (in %)',
        'sent-auto-product': 'Produkt-Questionnaire - Survey invitations sent out',
        'finished-auto-product': 'Produkt-Questionnaire - Answered questionnaires',
        'percentage-auto-product': 'Produkt-Questionnaire - Response rate (in %)',
        'sent-moto-service': 'Service-Questionnaire - Survey invitations sent out',
        'finished-moto-service': 'Service-Questionnaire - Answered questionnaires',
        'percentage-moto-service': 'Service-Questionnaire - Response rate (in %)',
        'sent-moto-sales': 'Sales-Questionnaire - Survey invitations sent out',
        'finished-moto-sales': 'Sales-Questionnaire - Answered questionnaires',
        'percentage-moto-sales': 'Sales-Questionnaire - Response rate (in %)',
        'sent-moto-product': 'Produkt-Questionnaire - Survey invitations sent out',
        'finished-moto-product': 'Produkt-Questionnaire - Answered questionnaires',
        'percentage-moto-product': 'Produkt-Questionnaire - Response rate (in %)',
      },
      'web-requests': {
        'test-drive': {
          'new-test-drive-request' : 'Test drive requests from last 7 days',
          'open-test-drive-request': 'New/open test drive requests',
        },
        offers: {
          private: 'New offers private customers',
          business: 'New offers business customers',
        },
        'car-configuration-offers': 'New offer requests from car configurator',
        'digital-brochures': 'New brochure download requests',
        'service-appointments': 'New service appointments',
      }
    },
  },
  'add-action': {
    title: 'Add action to contact',
    'action-type': 'ACTION TYPE',
    note: 'NOTE',

    cancel: 'Cancel',
    submit: 'Save action',
    options: {
      'conversation-log': 'conversation log',
      note: 'note',
    },
  },
  'hide-contact': {
    title: 'Hide contact',
    reason: 'Hiding reason',
    cancel: 'Cancel',
    submit: 'Ok',
    options: {
      'no-longer-serviced-by-me':
        'Customer no longer wants to be serviced by me',
      'deceased-closed': 'Deceased / Business closure',
      'fake-data-set': 'Fake data set',
      gdpr: 'GDPR',
      'no-data-release': 'No data release',
      other: 'Miscellaneous/Other',
    },
    note: 'Note',
    'other-reason-message-empty': 'Note can not be empty.',
    'successfully-hidden': 'Contact successfully hidden.',
  },
  'hide-vehicle': {
    title: 'Hide vehicle',
    reason: 'Hiding reason',
    cancel: 'Cancel',
    submit: 'Ok',
    options: {
      SOLD_TO_UNKNOWN: 'Hide (sold to unknown person)',
      OTHER: 'Hide (Miscellaneous/Other)',
    },
    note: 'Note',
    'other-reason-message-empty': 'Note can not be empty.',
    'successfully-hidden': 'Vehicle successfully hidden.',
    visible: 'Vehicle visible',
  },
  'deactivate-vehicle': {
    title: 'Deactivate vehicle',
    reason: 'Deactivating reason',
    cancel: 'Cancel',
    submit: 'Deactivate',
    options: {
      EXPORTED: 'Hide (exported)',
      STOLEN: 'Hide (stolen)',
      SCRAPPED: 'Hide (scrapped)',
    },
    note: 'Note',
    'successfully-deactivated': 'Vehicle successfully deactivated.',
    'deactivating-confirmation':
      'Are you sure that you want to deactivate this vehicle?',
  },
  appointment: {
    consulting_appointment: 'Consulting appointment',
    test_drive_appointment: 'Test drive appointment',
    service_appointment: 'Service appointment',
    service_appointment_reason: {
      regular: 'Regular Service Appointment',
      other: 'Other',
    },
    preferred_date: 'Preferred date',
    daytime: {
      forenoon: 'forenoon',
      afternoon: 'afternoon',
    },
    gearbox: {
      preferred_gearbox: 'Preferred gearbox',
      manual: 'manual',
      automatic: 'automatic',
      no_preference: 'no preference',
    },
    license_number: 'License number',
    vehicle_note: 'Note on the vehicle',
  },
  appointmentsChart: {
    title: 'Requests via online forms',
    appointments: 'Consulting Appointment Form',
    testDriveAppointments: 'Test Drive Request Form',
    serviceAppointments: 'Service Appointment Form',
    offers: 'Offer Request Form',
    week: 'CW',
    filter: {
      scale: {
        label: 'Unit Of Time',
        week: 'Calendar week',
        month: 'Month',
      },
      time: {
        from: 'From',
        to: 'Until',
      },
      forms: {
        label: 'Appointment Requesting Forms',
        all: 'All Forms',
        consulting: 'Consulting Appointment Form',
        testDrive: 'Test Drive Request Form',
        service: 'Service Appointment Form',
        offer: 'Offer Request Form',
      },
    },
  },
  'dealer-list': {
    title: 'Dealer',
    reimport: 'Re-import dealer',
    number: 'Dealer number',
    name: 'Name',
    address: 'Address',
    active: 'Active',
    actions: 'Actions',
    filter: {
      search: 'Dealer code, name, address...',
      status: {
        title: 'Status',
        deleted: 'show only inactive dealers',
        acitve: 'show only active dealers ',
      },
    },
    delete: {
      success: 'Dealer {{id}} was successfully deleted',
      fail: ' A problem occurred while deleting the {{id}} dealer ',
      existed: 'Dealer {{id}} is already deleted ',
    },
    description: {
      reimport: 'Re-import dealer ',
      reimporting: 'Dealer will be re-imported ',
      active: ' Dealer is active ',
      inactive: 'Dealer is inactive ',
      show: 'Show dealer ',
      delete: 'Delete dealer ',
    },
  },
  'remove-action-popup': {
    cancel: 'Cancel',
    user: {
      title: 'Really delete a user?',
      desc: 'Do you really want to permanently delete user {0}?',
      remove: 'Delete user',
    },
    dealer: {
      title: 'Really delete a dealer?',
      desc: 'Do you really want to permanently delete dealer {0}?',
      remove: 'Delete dealer',
    },
    'contact-pool': {
      title: 'Really delete a contact pool?',
      desc: 'Do you really want to permanently delete contact pool {0}?',
      remove: 'Delete contact pool',
    },
    action: {
      title: 'Really delete a action?',
      desc: 'Do you really want to permanently delete action?{0}',
      remove: 'Delete action',
    },
  },
  soldVehiclesChart: {
    title: "Vehicles sold after appointments",
    consultingAppointment: "Sold after consulting appointment",
    testDriveAppointment: "Sold after test drive appointment",
    testDrive: "Sold after test drive",
    groupBy: "Group By",
    week: "Week",
    month: "Month",
  },
  reports: {
    title: 'Reports',
    month: 'Month',
    year: 'Year',
    'please-select': 'Please select',
    freeText: {
      viewDetails: 'View details',
    },
    details: {
      prev: 'prev',
      next: 'next',
      total_count: 'Total count:',
      value: 'Value',
      percentage: 'Percentage',
      title: 'Details'
    },
    survey: {
      title: 'Survey reports',
      report: 'Report',
      types: {
        'moto-sales': 'Motorbike Sales-Questionnaire',
        'moto-service': 'Motorbike Service-Questionnaire',
        'moto-roadshow': 'Motorbike Roadshow-Questionnaire',
        'moto-product': 'Motorbike Product-Questionnaire',
        'auto-sales': 'Automobile Sales-Questionnaire',
        'auto-service': 'Automobile Service-Questionnaire',
        'auto-product': 'Automobile Product-Questionnaire',
      },
      no_data: 'No data available',
      monthly: 'monthly',
      yearly: 'yearly',
      weekly: 'weekly',
      week: 'CW',
      timeUnitFilter: 'Time Unit',
      time_unit_filter: 'Time Unit',
      from: 'From',
      to: 'To',
      export_excel: 'Export Excel',
      export_png: 'Export',
      send_out_per: 'Sendout',
      no_send_out_per: 'No Sendout',
      fully_completed_percentage: 'Response Completed',
      not_completed_percentage: 'Sendout, response not started',
      partly_completed_percentage: 'Response started, not completed',
      surveys_input: 'Surveys',
      model_filter: 'Model',
      date_type: 'Date type',
      send_out_date: 'Sendout date',
      response_date: 'Response date',
      send_out_rate: 'Sendout rate',
      response_rate: 'Response rate',
      fail_reason: 'Top 5 reasons for sendout failure',
      no_report_available:'No report has been created. Please contact the support to create one.',
    },
    crr: {
      title: 'Customer retantion rate (CRR)',
      filter: {
        year: 'Year type',
        'select-a-year': 'Select a year type',
        'calendar-year': 'Calendar year',
        'fiscal-year': 'Fiscal year',
        'fiscal-year-bonus': 'Fiscal year (Quality bonus)',
        'fiscal-year-inspection': '1st - 10th inspection',
        'fiscal-year-bonus-inspection': '1st - 5th inspection',
        period: 'Period',
        'calendar-year-select': 'Calendar year',
        'fiscal-year-select': 'Fiscal year',
        from: 'FROM',
        to: 'TO',
        'main-dealer': 'Main dealer',
        dealer: 'Dealer',
        'all-dealers': 'All dealers',
        asm: 'ASM',
        dsm: 'DSM',
        'asm-area': 'ASM',
      },
      'inspection-number': {
        title: {
          '0': '1.000km Inspection',
          '1': '1. Inspection',
          '2': '2. Inspection',
          '3': '3. Inspection',
          '4': '4. Inspection',
          '5': '5. Inspection',
          '6': '6. Inspection',
          '7': '7. Inspection',
          '8': '8. Inspection',
          '9': '9. Inspection',
          '10': '10. Inspection',
          '-1': 'Total',
        },        range: {
          '5' : 'Inspections  1. - 5.',
          '10' : 'Inspections  1. - 10.'
        },
        description: {
          '0': '',
          '1': '12 Months / 15.000km or 20 000km',
          '2': '24 Months / 30.000 km or 40.000 km',
          '3': '36 Months / 45.000 km or 60.000 km',
          '4': '48 Months / 60.000 km or 80.000 km',
          '5': '60 Months / 75.000 km or 100.000 km',
          '6': '72 Months / 90.000 km or 120.000 km',
          '7': '84 Months / 105.000 km or 140.000 km',
          '8': '96 Months / 120.000 km or 160.000 km',
          '9': '108 Months / 135.000 km or 180.000 km',
          '10': '120 Months / 150.000 km or 200.000 km',
          '-1': '{0} Inspection',
        },
      },
      metrics: {
        title: 'Metrics',
        planned: 'Number of planned {0}. inspections in the month',
        invited: 'Number of invitations to a {0}. inspection in the month',
        completed: 'Number of completed {0}. inspections',
        rate: 'Inspection completion rate (%) in the month',
        'cumulated-rate':
          'Inspection completion rate / customer retention rate (%) cumulative',
        zero: 'Number of 1,000 km inspections completed in the month',
      },
      sum: 'Sum',
      average: 'Average',
      'dealer-count': 'Considered dealers: ',
      date: 'Date: ',
      period: 'Period: ',
      download: {
        summary: 'Management-Summary (via Excel-Export)',
        excel: 'Excel-Export',
        csv: 'CSV-Export',
        pdf: 'PDF-Export',
      },
      planned: 'Planned',
      dealerCodes: 'Dealer codes: '
    },
    inspection: {
      title: 'Inspections',
      inspection: 'Inspection',
      'inspections-per-month': 'Inspections carried out per month',
      'planned-inspections':
        'Comparison of planned inspections to inspections carried out',
    },
  },

  help: {
    title : 'Help',
    'manual': {
      title: 'Manual',
      path : 'manual',
    },
    'sample-letter': {
      title: 'Sample letter',
    },
    'PDF_1': {
      title: 'Inspection',
      path : 'PDF_1',
    },
    'PDF_2': {
      title: 'Questionnaires',
      path :'PDF_2',
    },
    'zoom' : 'Zoom',
    'previous' : 'Previous',
    'next' : 'Next',
    'page' : 'Page',
    'download' : 'Download',
    'of':'of'
  },


  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  table: {
    'sort-title': 'sort',
    'select-title': 'select',
    'all-select-title': 'select all',
  },
  error: 'An error has occurred. Please contact support:  ',
  'error-vin': 'VIN could not be found! Please check our input again',
  'error-wrong-email': 'please enter a correct E-mail!',
  'error-required': 'Please complete all the required fields from',
  success: 'Saved successfully',

  support: {
    title: 'For help in using the CRM, the following ways are available:',
    'phone-hotline': 'Phone hotline',
    'support-email': 'Support e-mail:',
    'support-fax': 'Support fax:',
    'back-to-site': 'BACK TO SITE',
  },
  datePicker: {
    from: 'From',
    to: 'To',
    time: 'Time',
  },
  'text-line': {
    'not-specified': 'unknown',
  },
  dashboard: {
    title: 'Vehicle sales (first registration) by model',
    timeUnitFilter: 'Time Unit',
    weekly: 'Week',
    monthly: 'Month',
    modelByFilter: 'models',
    allModels: 'All models',
    dashboardTitle: 'All Sales Enquiries by Month',
    defaultModels: 'Current models',
    from: 'From',
    to: 'To',
    search: 'Search',
    selectAll: 'Select All',
  },
  'brochure-download-redirect': {
    'redirecting-message': 'Redirecting to your brochure...',
    'redirecting-error': 'Redirection to your brochure was not successful. Please, contact with the <1>support</1> to get more information.'
  },
  activate_newsletter_subscription: {
    sweepstake: {
      header: 'Thank you very much! And good luck!',
      body: 'Your registration was successful. You are now taking part in our sweepstake.',
    },
    success: {
      headline: 'Your newsletter subscription',
      body: `...was successful! Thank you for subscribing to our newsletter. You will receive all the news about Suzuki shortly.`,
      ending: 'Thank you and see you soon!'
    },
    failure: {
      headline: 'Something went wrong',
      body_moto: 'We were unable to complete your newsletter registration confirmation. Please try again later. Sorry, your request cannot be processed - No valid entry was found. Please subscribe to the newsletter again at <1>https://motorrad.suzuki.de/newsletter</1>',
      body_auto: 'We were unable to complete your newsletter registration confirmation. Please try again later. Sorry, your request cannot be processed - No valid entry was found. Please subscribe to the newsletter again at <1>https://auto.suzuki.de/ueber-suzuki/newsletter</1>',
      ending: 'Thank you very much! Your Suzuki Team'
    }
  },
  'unsubscribe-newsletter': {
    success: {
      headline: 'Your newsletter unsubscription',
      body: `...was successful! It's a pity you're leaving us. However, if you would like to subscribe to the newsletter again, you can find it <1>here</1>.`,
      ending: 'Thank you and see you soon!'
    },
    failure: {
      headline: 'Something went wrong',
      body_moto: 'Your newsletter unsubscription could not be carried out. No valid entry was found. Please sign up for the newsletter again at <1>https://motorrad.suzuki.de/newsletter</1>',
      body_auto: 'Your newsletter unsubscription could not be carried out. No valid entry was found. Please sign up for the newsletter again at <1>https://auto.suzuki.de/ueber-suzuki/newsletter</1>',

      ending: 'Thank you very much! Your Suzuki Team'
    }
  },
  'newsletter-search': {
    headline: 'Newsletter subscriptions',
    tenant: {
      auto: 'Auto',
      moto: 'Motorcycle',
    }
  },
  newsletter_export: {
    headline: 'Export history',
  },
  newsletter_definition: {
    headline: 'Newsletter definition',
  },
  'newsletter-export': {
    headline: 'Export history'
  },
  standard_controls: {
    cancel_uppercase: 'CANCEL',
    cancel_lowercase: 'Cancel',
    submit_ok: 'Ok',
    delete: 'Delete',
    back_uppercase: 'BACK',
    back_lowercase: 'Back',
  },
  error_popup: {
    unknown: 'An unknown error occurred.',
  },
  test_drive_action: {
    actionName: "Test drive request via {0} ({1})",
    model: "Model",
    date: "Test drive date/time",
    unknown: "Unknown",
    origin: {
      NATIONAL_WEBSITE: "National website",
      DEALER_WEBSITE: "Dealer's website",
      SCRM: "SCRM",
      TEST_DRIVE_WEEK: "Test drive week",
      DATA_COLLECTION_TOOL: "Data Collection Tool",
      IMOT_2024_MUNICH: "IMOT 2024 München",
      MOTORRAD_DORTMUND: "Motorrad Dortmund",
      ROADSHOW: "Roadshow",
      NOT_SET: "-",
    },
    status: {
      name: "Status",
      values: {
        NEW_OPEN: "Test drive new/open",
        SCHEDULED: "Test drive scheduled",
        FINISHED_CARRIED_OUT_BOUGHT: "Test drive completed (vehicle purchased)",
        FINISHED_CARRIED_OUT_NOT_BOUGHT:
          "Test drive completed (no vehicle purchase)",
        FINISHED_CARRIED_OUT_PURCHASE_OPEN:
          "Test drive completed (vehicle purchase pending)",
        FINISHED_CARRIED_OUT_PURCHASE_OPEN_HOT_LEAD:
          "Test drive completed (vehicle purchase pending (hot lead))",
        FINISHED_NOT_CARRIED_OUT: "Test drive finished (not carried out)",
        CANCELED: "Test drive finished (canceled)",
      },
    },
  },
};
